var tabs_el = document.getElementsByClassName('tabs')[0];
var tabs = document.getElementsByClassName('tab');
var tab_button_el = document.getElementsByClassName('tab_buttons')[0];

document.addEventListener('DOMContentLoaded', function(){
	[].forEach.call(tabs, function(tab){
		var close = function(){
			if (tab.querySelector('.tab_sub.active')){
				tab.querySelector('.tab_sub.active').classList.remove('active');
			}
			[].forEach.call(tabs, function(tab){
				tab.classList.remove('active');
				tab.classList.add('hidden');
			});
		};
		var activate = function(){
			close();
			tabs_el.classList.add('active');
			tab.classList.remove('hidden');
			tab.classList.add('active');

			// [].forEach.call(tab_button_el.getElementsByClassName('button'), function(b){
			// 	b.classList.remove('active');
			// })
			// tab_button_el.querySelector('[data-tab="'+tab.dataset.tab+'"]').classList.add('active')
		}
		var deactivate = function(){
			close();
		}


		var readMore = tab.getElementsByClassName('read-more')[0];
		readMore.addEventListener('click', activate, true);

		var readLess = tab.getElementsByClassName('read-less')[0];
		readLess.addEventListener('click', deactivate, true);

		
		
		var subs = tab.getElementsByClassName('tab_sub');
		[].forEach.call(subs, function(sub){
			var button = sub.getElementsByClassName('tab_sub_title')[0];
			if (button){
				button.addEventListener('click', function(){
					if (sub.classList.contains('active')){
						sub.classList.remove('active');
						return;
					}
					if (tab.querySelector('.tab_sub.active')){
						tab.querySelector('.tab_sub.active').classList.remove('active');
					}
					sub.classList.add('active');
				})
			}
		})
		
		
	})
});