
var titles = document.getElementsByClassName('title');
var columns = document.getElementsByClassName('column');
var open_columns = document.getElementsByClassName('open');
var closed_columns = document.getElementsByClassName('closed');
var resizers = document.getElementsByClassName('resizer');

var home_close = document.getElementsByClassName('home-close')[0];
var ratio = 20;
var narrow = 180;

var res_interval = [];

var layout = function(){
	console.log(open_columns.length, closed_columns.length)

	open_columns = document.getElementsByClassName('open');
	closed_columns = document.getElementsByClassName('closed');

	[].forEach.call(closed_columns,function(o){
		o.removeAttribute('style');
	})
	if (window.innerWidth >= 960){
		available_width = window.innerWidth;
		if (closed_columns.length){
			available_width = available_width - (closed_columns[0].offsetWidth * closed_columns.length);	
		}
		
		if (dragging = document.querySelector('.open.dragging')){
			available_width = available_width - dragging.offsetWidth;
		}

		[].forEach.call(open_columns, function(open_column){
			open_column.style.width = Math.floor(available_width / open_columns.length) + 'px';
			open_column.style.height = '100%';
			open_column.style.fontSize = Math.floor(available_width / open_columns.length) / ratio + 'px';
		});
		
	}else{
		available_height = window.innerHeight;
		if (closed_columns.length){
			available_height = available_height - (closed_columns[0].offsetHeight * closed_columns.length);	
		}
		[].forEach.call(open_columns, function(open_column){
			open_column.style.height = Math.floor(available_height / open_columns.length) + 'px';
			open_column.style.width = '100%';
		});
	}

	
	
};
window.addEventListener('resize', layout)
document.addEventListener('DOMContentLoaded', function(){
	layout();
	[].forEach.call(titles,function(title){
		title.addEventListener('click', function(){
			
			
			if (window.innerWidth < 960){
				[].forEach.call(document.getElementsByClassName('open'),function(o){
					o.classList.remove('open');
					o.classList.add('closed');
					o.removeAttribute('style');
				});
				title.parentNode.classList.add('open');
				title.parentNode.classList.remove('closed');
				
			}else{
				title.parentNode.classList.toggle('open');
				title.parentNode.classList.toggle('closed');
				if (title.parentNode.classList.contains('closed')){
					title.parentNode.removeAttribute('style');
					title.parentNode.classList.remove('narrow')
				}

				if (document.getElementsByClassName('open').length == 0){
					document.getElementsByClassName('closed')[0].classList.add('open');
				}
			}
			
			layout();
		})
	});
	home_close.addEventListener('click', function(){
		if (window.innerWidth >= 960)
			return;


		columns[0].classList.remove('open');
		columns[0].classList.add('closed');
		

		if (document.getElementsByClassName('open').length == 0){
			columns[1].classList.add('open');
			columns[1].classList.remove('closed');
		}

		open_columns = document.getElementsByClassName('open');
		closed_columns = document.getElementsByClassName('closed');
		layout();
	});
	// if (document.getElementById('formula')){
	// 	document.getElementById('formula').addEventListener('submit', function(e){
	// 		e.preventDefault();
	// 		// [].forEach.call(open_columns, function(oc){
	// 		// 	if (!oc.classList.contains('play')){
	// 		// 		oc.classList.remove('open');
	// 		// 		oc.classList.add('closed');
	// 		// 	}
				
	// 		// })
	// 		layout();
	// 	})
	// }
});

