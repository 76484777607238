document.addEventListener("DOMContentLoaded", function(){
  if (!document.getElementById('formula')){
    return;
  }
  var form = document.getElementById('formula');
  var range = form.getElementsByClassName('range')[0];
  var rangeOut = form.getElementsByClassName('rangeOut')[0];
  var output = form.getElementsByClassName('output')[0];
  var utilOut = document.getElementsByClassName('utilOut')[0];
  var utilOutDiv = document.getElementsByClassName('outputDivUtil')[0];
  var numbers = document.getElementsByClassName('label');
  var form_column = document.querySelector('.column.play .inner');
  var wrapper = document.getElementsByClassName('wrapper')[0];
  var duration = 500;
  var linear = function(t, b, c, d) {
      var ts = (t /= d) * t;
      var tc = ts * t;
      return b + c * (1.77635683940025e-15 * tc * ts + 0.999999999999998 * tc + -3 * ts + 3 * t);
  };
  var options = {
    useEasing : false, 
    useGrouping : true, 
    separator : ',', 
  };
  [].forEach.call(numbers,function(n){
    var input = n.querySelector('input');
    var plus = minus = [];

    n.querySelector('.plus').addEventListener('mousedown', function(){
      plus = setInterval(function(){
        if (input.getAttribute('max')){
          if (parseFloat(input.value) <= parseFloat(input.getAttribute('max'))){
            input.value = parseFloat(input.value) + 1; 
          }else{
            clearInterval(plus)
          }
        }else{
          input.value = parseFloat(input.value) + 1;
        }  
      }, 50);
    });
    n.querySelector('.plus').addEventListener('mouseup', function(){
      clearInterval(plus);
    });



    n.querySelector('.minus').addEventListener('mousedown', function(){
      clearInterval(plus);
      minus = setInterval(function(){
        if (input.getAttribute('min')){
          if (parseFloat(input.value) > parseFloat(input.getAttribute('min'))){
            input.value = parseFloat(input.value) - 1; 
          }else{
            clearInterval(minus)
          }
        }else{
          input.value = parseFloat(input.value) - 1;
        }
      }, 50);
    });
    n.querySelector('.minus').addEventListener('mouseup', function(){
      clearInterval(minus);
    });
    n.querySelector('.minus').addEventListener('contextmenu', function(e){
      e.preventDefault();
      clearInterval(minus);
    });


     n.querySelector('.plus').addEventListener('touchstart', function(){
      clearInterval(minus);
      plus = setInterval(function(){
        if (input.getAttribute('max')){
          if (parseFloat(input.value) <= parseFloat(input.getAttribute('max'))){
            input.value = parseFloat(input.value) + 1; 
          }else{
            clearInterval(plus)
          }
        }else{
          input.value = parseFloat(input.value) + 1;
        }  
      }, 50);
    });
    n.querySelector('.plus').addEventListener('touchend', function(){
      clearInterval(plus);
    });
    n.querySelector('.plus').addEventListener('contextmenu', function(e){
      e.preventDefault();
      clearInterval(plus);
    });
    window.addEventListener("orientationchange", function() {
      clearInterval(plus);
      clearInterval(minus);
    })


    n.querySelector('.minus').addEventListener('touchstart', function(){
      minus = setInterval(function(){
        if (input.getAttribute('min')){
          if (parseFloat(input.value) > parseFloat(input.getAttribute('min'))){
            input.value = parseFloat(input.value) - 1; 
          }else{
            clearInterval(minus)
          }
        }else{
          input.value = parseFloat(input.value) - 1;
        }
      }, 50);
    });
    n.querySelector('.minus').addEventListener('touchend', function(){
      clearInterval(minus);
    });
  });


  var listener = function() {
    window.requestAnimationFrame(function() {
      switch (parseInt(range.value)){
        case 0: 
          range.dataset.trueVal = 0.1;
          rangeOut.textContent = "very low"
          break;
        case 4:
          range.dataset.trueVal = 0.2;
          rangeOut.textContent = "low"
          break;
        case 8:
          range.dataset.trueVal = 0.4;
          rangeOut.textContent = "high"
          break;
        case 12:
          range.dataset.trueVal = 0.8;
          rangeOut.textContent = "very high"
          break;
      }
    });
  };

  range.addEventListener("mousedown", function() {
    listener();
    range.addEventListener("mousemove", listener);
  });
  range.addEventListener("mouseup", function() {
    range.removeEventListener("mousemove", listener);
  });

  range.addEventListener("touchstart", function() {
    listener();
    range.addEventListener("touchmove", listener);
  });
  range.addEventListener("touchend", function() {
    range.removeEventListener("touchmove", listener);
  });
  

  form.addEventListener('submit', function(e){
    e.preventDefault();
    
    var a = parseFloat(form.querySelector('[name="a"]').value);
    var b = parseFloat(form.querySelector('[name="b"]').value);
    var c = parseFloat(form.querySelector('[name="c"]').value);
    var d = parseFloat(range.dataset.trueVal);

    var r = c / b;
    t = r / (r+1);

    var result = Math.floor(normsInv(t, a, a*d)) + 1;
    result = (parseInt(result) == result)?result:a;
    var util = (Math.floor( (a/result) * 100) < 100)?Math.floor( (a/result) * 100):100;
    
    var numAnim = new CountUp("numDesks", 0, result, duration/1000, options);
    numAnim.start();

    var utilAnim = new CountUp("optimDesks", 0, util, duration/1000, options);
    utilAnim.start();
    // utilOutDiv.style.height = util + '%';
    // utilOutDiv.style.transform = "translateY("+util+"%)";
    Velocity(utilOutDiv,{
      paddingBottom: util+"%"
    },{
      duration: duration,
      easing: "linear"
    })

    utilOutDiv.parentNode.parentNode.classList.add('active');
    wrapper.classList.add('blurred');
  });
  utilOutDiv.parentNode.parentNode.addEventListener('click', function(){
    utilOutDiv.parentNode.parentNode.classList.remove('active');
    wrapper.classList.remove('blurred');
    Velocity(utilOutDiv,{
      paddingBottom: 0+"%"
    },{
      duration: 0
    })
  })

  
});


function normsInv(p, mu, sigma){
    if (p < 0 || p > 1){
        throw "The probality p must be bigger than 0 and smaller than 1";
    }
    if (sigma < 0){
        throw "The standard deviation sigma must be positive";
    }

    if (p == 0){
        return -Infinity;
    }
    if (p == 1){
        return Infinity;
    }
    if (sigma == 0){
        return mu;
    }

    var q, r, val;

    q = p - 0.5;

    /*-- use AS 241 --- */
    /* double ppnd16_(double *p, long *ifault)*/
    /*      ALGORITHM AS241  APPL. STATIST. (1988) VOL. 37, NO. 3
            Produces the normal deviate Z corresponding to a given lower
            tail area of P; Z is accurate to about 1 part in 10**16.
    */
    if (Math.abs(q) <= .425){
      /* 0.075 <= p <= 0.925 */
        r = .180625 - q * q;
        val =
               q * (((((((r * 2509.0809287301226727 +
                          33430.575583588128105) * r + 67265.770927008700853) * r +
                        45921.953931549871457) * r + 13731.693765509461125) * r +
                      1971.5909503065514427) * r + 133.14166789178437745) * r +
                    3.387132872796366608)
               / (((((((r * 5226.495278852854561 +
                        28729.085735721942674) * r + 39307.89580009271061) * r +
                      21213.794301586595867) * r + 5394.1960214247511077) * r +
                    687.1870074920579083) * r + 42.313330701600911252) * r + 1);
    }else{ /* closer than 0.075 from {0,1} boundary */

        /* r = min(p, 1-p) < 0.075 */
        if (q > 0)
            r = 1 - p;
        else
            r = p;

        r = Math.sqrt(-Math.log(r));
        /* r = sqrt(-log(r))  <==>  min(p, 1-p) = exp( - r^2 ) */

        if (r <= 5){ /* <==> min(p,1-p) >= exp(-25) ~= 1.3888e-11 */
            r += -1.6;
            val = (((((((r * 7.7454501427834140764e-4 +
                       .0227238449892691845833) * r + .24178072517745061177) *
                     r + 1.27045825245236838258) * r +
                    3.64784832476320460504) * r + 5.7694972214606914055) *
                  r + 4.6303378461565452959) * r +
                 1.42343711074968357734)
                / (((((((r *
                         1.05075007164441684324e-9 + 5.475938084995344946e-4) *
                        r + .0151986665636164571966) * r +
                       .14810397642748007459) * r + .68976733498510000455) *
                     r + 1.6763848301838038494) * r +
                    2.05319162663775882187) * r + 1);
        }else{ /* very close to  0 or 1 */
            r += -5;
            val = (((((((r * 2.01033439929228813265e-7 +
                       2.71155556874348757815e-5) * r +
                      .0012426609473880784386) * r + .026532189526576123093) *
                    r + .29656057182850489123) * r +
                   1.7848265399172913358) * r + 5.4637849111641143699) *
                 r + 6.6579046435011037772)
                / (((((((r *
                         2.04426310338993978564e-15 + 1.4215117583164458887e-7) *
                        r + 1.8463183175100546818e-5) * r +
                       7.868691311456132591e-4) * r + .0148753612908506148525)
                     * r + .13692988092273580531) * r +
                    .59983220655588793769) * r + 1);
        }

        if (q < 0.0)
        {
            val = -val;
        }
    }

    return mu + sigma * val;
}