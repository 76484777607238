var team = document.getElementsByClassName('team');

document.addEventListener('DOMContentLoaded', function(){
	[].forEach.call(team, function(t){
		var title = t.getElementsByClassName('team_title')[0];
		var thumb = t.getElementsByClassName('team_thumb')[0]

		var clone = t.getElementsByClassName('team_thumb')[0].cloneNode(true);
		var show = function(e){
			e.preventDefault();
			// t.classList.add('thumb')
			clone.classList.add('thumb')
			document.body.appendChild(clone);
			if (window.innerWidth >= 960){
				wrapper.classList.add('blurred');	
			}
			
		};

		var hide = function(e){
			e.preventDefault();
			// t.classList.remove('thumb')
			if (document.body.contains(clone)){
				document.body.removeChild(clone);
			}

			wrapper.classList.remove('blurred');
		};

		title.addEventListener('click', function(e){
			if (t.classList.contains('active')){
				t.classList.remove('active');
				return
			}
			// [].forEach.call(team, function(te){
			// 	te.classList.remove('active');
			// });
			t.classList.add('active');
			hide(e);
		});
		thumb.addEventListener('click', function(e){
			if (t.classList.contains('active')){
				t.classList.remove('active');
				return
			}
			// [].forEach.call(team, function(te){
			// 	te.classList.remove('active');
			// });
			t.classList.add('active');
			hide(e);
		});
		if (window.innerWidth > 960){
			
				title.addEventListener('mouseover', show);
				title.addEventListener('mouseout', hide);
				title.addEventListener('touchstart', show);
				title.addEventListener('touchend', hide);
			
		}
		
	});
});