var animations = document.querySelectorAll('.anim-hover, .animation');
var anim_holder = document.querySelector('.intro_thumb');
var wrapper = document.getElementsByClassName('wrapper')[0];

var touch_device = function() {
    return 'ontouchstart' in document.documentElement;
};

[].forEach.call(animations, function(anim){
	var show = function(e){
		e.preventDefault();
		[].forEach.call(animations, function(a){
			a.classList.add('inactive')
		});
		anim.classList.remove('inactive')
		if (anim.dataset.anim){
			document.querySelector('.anim[data-name="'+anim.dataset.anim+'"]').classList.add('active');
		}else{
			document.querySelector('.anim.intro').classList.add('active');
		}
		wrapper.classList.add('blurred');
	};

	var hide = function(e){
		e.preventDefault();
		if (document.querySelector('.anim.active')){
			document.querySelector('.anim.active').classList.remove('active');	
		}
		
		[].forEach.call(animations, function(a){
			a.classList.remove('inactive')
		});
		wrapper.classList.remove('blurred');
	};
	
	anim.addEventListener('touchstart', show);
	anim.addEventListener('touchend', hide);
	anim.addEventListener('mouseover', show);
	anim.addEventListener('mouseout', hide);
	window.addEventListener("orientationchange", hide);
	
	

});




